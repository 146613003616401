import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'


window.$ = $
Vue.use(ElementUI)

Vue.config.productionTip = false
ElementUI.Dialog.props.lockScroll.default = false

import axios from 'axios'
// axios.defaults.headers.post['Content-Type'] = 'application/json'
Vue.prototype.$http = axios.create();
Vue.prototype.$http1 = axios.create();


// Vue.prototype.$http1.defaults.baseURL= 'http://192.168.5.147/';
// Vue.prototype.$http.defaults.baseURL= 'http://192.168.5.147:8080/';
// Vue.prototype.$http1.defaults.baseURL= window.location.protocol + '//' + window.location.hostname + '/';
// Vue.prototype.$http.defaults.baseURL= 'http://xt.zeiot.cn:998' + '/';
// Vue.prototype.$http.defaults.baseURL= window.location.protocol + '//' + window.location.host + '/';
// Vue.prototype.$http1.defaults.baseURL= 'http://xt.zeiot.cn:998' + '/';

// 发布环境
// Vue.prototype.$http1.defaults.baseURL= 'http://124.71.131.218/';
// Vue.prototype.$http.defaults.baseURL= 'http://124.71.131.218:8080/';


Vue.prototype.$http1.defaults.baseURL= window.location.protocol== 'https:' ?  process.env.VUE_APP_baseurl2s : process.env.VUE_APP_baseurl2;
Vue.prototype.$http.defaults.baseURL= window.location.protocol== 'https:' ? process.env.VUE_APP_baseurls : process.env.VUE_APP_baseurl;



// axios.interceptors.request.use(
//   (config) => {
//     if (config.url.indexOf("ak") > -1) {
//       config.baseURL = "";
//     }
//   }
// )

Vue.prototype.$http.interceptors.response.use(
  (config) => {
 
      if(config.status == 401) {
        localStorage.removeItem('brd-aLL')
          router.push({name:'login'})
          // localStorage.removeItem('token')
          // this.$router.push({name: 'login'})
      }

      return config
  }
)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// import { detectZoom } from '@/utils/detectZoom.js';
// const m = detectZoom();
// document.body.style.zoom = 100 / Number(m);


window.addEventListener('resize', function() {
  let winFlag = window.innerHeight === window.screen.height
  console.log(winFlag,'winFlag全屏有了效果',window.location,window.location.href)
  if(window.location.href.indexOf('login') > 0 ) {
    return
  }     
  // window.location.reload()
  
  if(winFlag) {
    console.log('全屏效果111')
    // document.body.style.zoom = "1"
    // window.location.reload()
    if(window.screen.width == 1920) {

      document.body.style.zoom = "1"
      window.location.reload()

    }
    else {
        document.body.style.zoom = "0.85"
        window.location.reload()
        
    }   


  }else{
    if(window.screen.width == 1920) {

      document.body.style.zoom = "0.89"
        window.location.reload()
        this.$refs.pagination.dispatch('ElPagination','layout')
    // }else if(window.screen.width == 2560){
      // let now_width = window.screen.width * window.devicePixelRatio;   这个在2560的屏幕里面拿到的宽度是2560.5
    }else {
        document.body.style.zoom = "0.74"
        window.location.reload()
    }      
    // this.quanpingstatus=false  
    

  }
}) ;


